import {NotFoundScreen} from '@src/features/auth/screens/NotFoundScreen';
import {GetStaticProps} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import {useTranslation} from 'next-i18next';

const Error404 = () => {
  const {t} = useTranslation();
  return (
    <>
      <Head>
        <title>{t('error:not-found')}</title>
      </Head>
      <NotFoundScreen />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({locale}) => {
  if (!locale) {
    throw new Error('locale is undefined');
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['error', 'configuration'])),
    },
  };
};

export default Error404;
