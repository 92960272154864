import {CustomButton} from '@src/common/inputs/CustomButton';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

const Overlay = styled.main`
  flex: 1;
  height: 100vh;
  background-color: #9f74eb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-size: 200px;
  color: #2f3071;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 6px;
`;

const Content = styled.p`
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
  color: #2f3071;
  width: 50%;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 327px;
  margin-top: 40px;
`;

export const NotFoundScreen = () => {
  const {t} = useTranslation();
  const router = useRouter();

  return (
    <Overlay>
      <Title>404</Title>
      <Content>{t('error:not-found-content')}</Content>

      <ButtonContainer>
          <CustomButton
            buttonType="grey"
            type="button"
            label={t('error:go-to-main')}
            onClick={() => router.push("/")}
          />
      </ButtonContainer>
    </Overlay>
  );
};
